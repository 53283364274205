
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { Organisation } from '@/ts/models/organisation'
  import { OrganisationsState } from '@/ts/states/admin/organisationsState'
  import { IScrollContext } from '@/ts/interfaces/scrollContext'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class VenueSearchResults extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: OrganisationsState


    /** PRIVATE PROPERTIES */

    private scrollContext?: IScrollContext


    /** LIFECYCLE */

    public created() {
      if (!this.state.hasState) {
        this.state.fetch(this.state.queryParams)
      }
    }

    /** OBSERVERS */


    @Watch('organisations', { immediate: false, deep: false })
    protected onOrganisationsChanged() {
      this.scrollContext?.loaded()
    }

    
    /** COMPUTED PROPERTIES */

    protected get hasMoreResults() {
      return this.state.hasMoreResults
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.state.status == ViewStatus.SUCCEEDED
    }

    protected get isVisible() {
      return this.status > ViewStatus.NONE
    }

    protected get organisations() {
      return this.state.organisations
    }

    protected get queryParams() {
      return this.state.queryParams
    }

    protected get status() {
      return this.state.status
    }


    /** EVENTS */

    protected onInfinite(context: IScrollContext) {
      this.scrollContext = context
      ++this.queryParams.currentPage
      this.state.fetch(this.queryParams)
    }

    protected onRowClicked(organisation: Organisation) {
      this.$emit('click', organisation)
    }

    protected onSortChanged(context: ISortContext) {
      this.$emit('sort', context)
    }

    protected onSort(context: ISortContext) {
      this.state.sort(context)
    }
  }

