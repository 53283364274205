
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { OrganisationsState } from '@/ts/states/admin/organisationsState'
  import { QueryParams } from '@/ts/api/queryParams'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  


  @Component
  export default class VenueSearchForm extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: OrganisationsState


    /** PRIVATE PROPERTIES */

    protected queryParams = this.state.queryParams

    private authState = new AuthState(this.$store)


    /** OBSERVERS */

    @Watch('state.queryParams')
    protected onQueryParamsChanged(queryParams: QueryParams) {
      this.queryParams = queryParams
    }


    /** COMPUTED PROPERIES */

    protected get canAddCustomer() {
      return this.authState.user.hasPermission(Claims.ADMIN_CUSTOMERS, Crud.CREATE)
    }

    protected get searchText() {
      return (this.state.status == ViewStatus.IN_PROGRESS) ? 'Searching...' : 'Update Search'
    }


    /** EVENTS */

    protected onSubmit() {
      this.queryParams.currentPage = 1
      this.state.clearResults()
      this.state.fetch(this.queryParams)
    }
  }
