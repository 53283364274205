
  import { Component, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { QueryParams } from '@/ts/api/queryParams'
  import { OrganisationsState } from '@/ts/states/admin/organisationsState'
  import { Route } from '@/ts/models/route'

  import BackToLink from '@/components/links/BackToLink.vue'
  import TerminalStationSearch from '@/components/terminal-stations/TerminalStationSearch.vue'
  import VenueDetails from './components/VenueDetails.vue'


  @Component({
    components: {
      BackToLink,
      TerminalStationSearch,
      VenueDetails
    }
  })
  export default class Venue extends Vue {

    /** PRIVATE PROPERTIES */

    protected params!: QueryParams
    protected route?: Route
    protected state = new OrganisationsState(this.$store)

    private authState = new AuthState(this.$store)


    /** LIFECYCLE */

    public created() {
      this.route = this.state.returnRoute
    }


    /** COMPUTED PROPERTIES */

    protected get canSeeTerminals() {
      return this.authState.user.hasPermission(Claims.ADMIN_STATIONS, Crud.READ)
    }

    protected get status() {
      return this.state.status
    }


    /** EVENTS */

    protected onClick(id: number) {
      console.log(id)
    }
  }
  

