
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { User } from '@/ts/models/user'
  import { UsersState } from '@/ts/states/admin/usersState'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class UserSearchResults extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: UsersState


    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.state.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.status > ViewStatus.NONE
    }

    protected get queryParams() {
      return this.state.queryParams
    }

    protected get status() {
      return this.state.status
    }

    protected get users() {
      return this.state.users
    }


    /**  PRIVATE METHODS */

    protected canEditOperators(user: User) {
      if (!this.authState.user.hasPermission(Claims.ADMIN_OPERATORS, Crud.UPDATE)) {
        return false
      }

      return this.authState.user.roles[0].level < user.roles[0].level
    }


    /** EVENTS */

    protected onPageChanged(page: number) {
      this.state.paginate(page)
    }

    protected onRowClicked(user: User) {
      this.$emit('click', user)
    }

    protected onSortChanged(context: ISortContext) {
      this.state.sort(context)
    }
  }

