import { ApiRepository } from './apiRepository'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'
import { TerminalStation, ITerminalStation } from '../models/terminalStation'


export class TerminalStationRepository extends ApiRepository<TerminalStation, ITerminalStation> {

  constructor(path = Paths.TERMINAL_STATIONS, type: new (i?: ITerminalStation) => TerminalStation = TerminalStation) {
    super(path, type)
  }

  public async getTerminalStations(queryParams: QueryParams, organisationId?: number) {
    const url = (organisationId) ? this.urlPath(Paths.ORGANISATIONS, organisationId, [this.path]) : this.path;
    return this.getResponse(url, queryParams)
  }
}
