
  import { Component, Vue } from 'vue-property-decorator'
  import { TerminalStationsState } from '@/ts/states/admin/terminalStationsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import TerminalStationSearch from '@/components/terminal-stations/TerminalStationSearch.vue'
 


  @Component({
    components: {
      TerminalStationSearch
    }
  })
  export default class TerminalStations extends Vue {


    /** PRIVATE PROPERTIES */

    protected state = new TerminalStationsState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get status() {
      return this.state.status
    }
  }

