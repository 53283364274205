import { Editor } from './editor'
import { TerminalStation } from '@/ts/models/terminalStation'
import { TerminalStationRepository } from '../repositories/terminalStationRepository'
import { Store } from 'vuex'

/**
 * An editor for Terminal Locations
 * @class
 */
export class TerminalStationEditor extends Editor<TerminalStation> {


  /**
  * The repository to make API requests
  * @private
  * @property
  */
  private repo = new TerminalStationRepository()


  /**
   * Instantiates a new editor
   * @constructor
   * @param original
   */
  constructor(original = new TerminalStation(), store: Store<any>) {
    super(original, store)
  }


  /**
  * Resets the editor to a new Terminal Location
  * @function
  */
  public reset() {
    this.edit = new TerminalStation()
    this.original = new TerminalStation()
  }

  /**
   * Saves the current edits to a terminal operator and resets the editor when complete
   * @function
   */
  public async save() {
    await this.repo.save(this.edit)
    this.reset()
  }
}
