import { Store } from 'vuex'

import { ISortContext } from '@/ts/interfaces/sortContext'
import { QueryParams } from '@/ts/api/queryParams'
import { Organisation } from '@/ts/models/organisation'
import { Route } from '@/ts/models/route'
import { ViewStatus } from '@/ts/enums/viewStatus'

import { getModule } from 'vuex-module-decorators'

import OrganisationsModule from '@/ts/store/admin/organisationsModule'
import { TerminalStationsState } from './terminalStationsState'

import _ from 'lodash'

/**
 * State management of Organisation searches and UI
 * @class
 */
export class OrganisationsState {

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE

  /**
   * The Terminal Stations State for displaying a list of Terminal Stations
   * within the UI when a specific Organisation has been selected, or 
   * @property
   * @see {@link TerminalStationsState}
   * @returns {TerminalStationsState}
   */
  public terminalStations: TerminalStationsState


  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link OrganisationsModule}
   * @returns {OrganisationsModule}
   */
  private module: OrganisationsModule


  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(OrganisationsModule, store)
    this.terminalStations = new TerminalStationsState(store)

    if (this.organisation != null) {
      this.terminalStations.organisation = this.organisation
    }

    const queryParams = this.queryParams.copy()
    queryParams.sortBy = 'name'
    this.queryParams = queryParams

    this.setStatus()
  }

  
  /**
   * The total number of Organisations which match the search criteria
   * @property
   * @returns {number}
   */
  public get count() {
    return this.module.count
  }

  /**
   * Whether there are more Organisations that can be retrieved or
   * they have all been retrieved already
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return this.module.iOrganisations.length < this.count
  }

  /**
   * Whether the current state has retrieved any Organisations to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.module.iOrganisations.length > 0
  }

  /**
   * The number of Organisations which have been retrieved so far
   * @property
   * @returns {number}
   */
  public get length() {
    return this.module.iOrganisations.length
  }

  /**
   * The Organisations which have been retrieved so far
   * @property
   * @returns {Organisation[]}
   */
  public get organisations() {
    return this.module.iOrganisations.map(i => new Organisation(i))
  }

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public get queryParams() {
    return new QueryParams(this.module.queryParams)
  }

  public set queryParams(value: QueryParams) {
    this.module.setQueryParams(value)
  }

  /**
   * The Organisation of the current NightKey user, or the selected Organisation if more than
   * one exists (i.e, for a NightKey Admin user)
   * @property
   * @see {@link Organisation}
   * @returns {Organisation}
   */
  public get organisation() {
    return (this.module.iOrganisation != null) ? new Organisation(this.module.iOrganisation) : undefined
  }

  public set organisation(value: Organisation | undefined) {
    this.module.setOrganisation(value)
    if (value != null) {
      this.terminalStations.organisation = value
    }
  }

  /**
   * The previous Route which should be used to allow a user to go back to a previous screen.
   * This method does not pop the Route of the navigation stack.
   * @property
   * @see {@link Route}
   * @returns {Route}
   */
  public get returnRoute() {
    const iRoute = _.last(this.module.returnRoutes)
    return (iRoute) ? new Route(iRoute.name, iRoute.params, iRoute.displayName) : undefined
  }

  /**
   * The Y scroll position of the current page
   * @property
   * @returns {number}
   */
  public get scrollPosition() {
    return this.module.scrollPosition
  }

  public set scrollPosition(value: number) {
    this.module.setScrollPosition(value)
  }

  /**
   * A key/value map to display Organistations in a table
   * @property
   */
  public get tableFields() {
    return [
      {
        key: 'name',
        label: 'Venue',
        sortable: true
      },
      {
        key: 'state',
        label: 'Location',
        sortable: true
      },
      {
        class: 'text-center',
        key: 'organisationTypeId',
        label: 'Account',
        sortable: true
      },
      {
        key: 'contact',
        label: 'Contact name',
        sortable: true
      },
      {
        class: 'text-center wpx-50',
        key: 'isActive',
        label: 'Active',
        sortable: true
      },
      {
        class: 'text-right',
        key: 'more',
        label: '',
        sortable: false
      }
    ]
  }

  /**
   * Clears the entire state and resets the status back to NONE
   * @function
   * @returns {void}
   */
  public clear() {
    this.module.clear()
    this.terminalStations.clear()
    this.status = ViewStatus.NONE
  }

  /**
   * Clears the Organisations currently retrieved and sets Count back to 0
   * @function
   * @returns {void}
   */
  public clearResults() {
    this.module.clearResults()
  }

  /**
   * Retrieves a list of Organisations from the Store which match the specified criteria
   * specified in the Query Params. If the current page is the first page, the status
   * will be set to In Progress otherwise the status is Updating
   * @function
   * @param {QueryParams} queryParams - A pre-defined set of parameters to filter and sort results
   * @returns {Organisation[]} The list of Organisations
   */
  public async fetch(queryParams: QueryParams) {
    this.status = (queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getOrganisations(queryParams)
  }

  /**
   * Removes the last route from the navigation stack and returns it.
   * This should be called when the user returns to the page by clicking
   * on a back link from the UI.
   * @function
   * @returns {Route} The last route from the navigation stack
   */
  public popRoute() {
    const route = _.last(this.module.returnRoutes)
    this.module.popRoute()
    return route
  }

  /**
   * Adds a new route to the navigation stack.
   * This should be called when the user navigates away from a page but will
   * have the option to return back on the destination page.
   * stack with a new route
   * @function
   * @param {Route} route - The route to add to the stack
   * @param {boolean} replace - Whether to replace all existing routes with this route
   * @return {void}
   */
  public pushRoute(route: Route, replace = false) {
    if (replace) {
      this.module.clearRoutes()
    }
    this.module.pushRoute(route)
  }

  /**
   * Retrieves a fresh list of Organisations based on the current search criteria but in
   * a new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {Organisation[]} The list of sorted Organisations
   */
  public async sort(context: ISortContext) {
    this.status = ViewStatus.SORTING
    const queryParams = this.queryParams.sortedBy(context)
    return await this.getOrganisations(queryParams)
  }

  /**
   * Retrieves a list of Organisations from the Store using the current state Query Params
   * that have been populated from the UI.
   * @private
   * @function
   * @param {QueryParams} queryParams - A pre-defined set of parameters to filter and sort results
   * @returns {Organisation[]} The list of Organisations
   */
  private async getOrganisations(queryParams: QueryParams) {
    try {
      this.queryParams = queryParams

      await this.module.getOrganisations(queryParams)
      this.status = ViewStatus.SUCCEEDED

      return this.organisations
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Sets the current status based on whether Organisations have already been
   * retrieved or not
   * private 
   * @function
   * @return {void}
   */
  private setStatus() {
    this.status = (this.module.iOrganisations.length > 0) ? ViewStatus.SUCCEEDED : ViewStatus.NONE
  }
}
