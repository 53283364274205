import { Store } from 'vuex'

import { AuthState } from '../auth/authState'
import { LookupState } from '../lookup/lookupState'
import { Pagination } from '@/ts/models/pagination'
import { QueryParams } from '@/ts/api/queryParams'
import { Route } from '@/ts/models/route'
import { User } from '@/ts/models/user'
import { UserEditor } from '@/ts/editors/userEditor'
import { UserRepository } from '@/ts/repositories/userRepository'
import { UserRole } from '@/ts/models/userRole'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { ViewStatus } from '@/ts/enums/viewStatus'

import _ from 'lodash'

/**
 * State management of Terminal Operator searches and UI
 * @class
 */
export class UsersState {

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE

  /**
   * A Terminal Operator Editor that is used to store the original
   * values of the editor along with the changed/new values for
   * editing purposes/
   * @property
   * @see {@link UserEditor}
   * @returns {UserEditor}
   */
  public user: UserEditor

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link UserRole}
   * @returns {UserRole[]}
   */
  public userRoles = new Array<UserRole>()



  /**
* The Auth State
* @property
* @see {@link AuthState}
* @returns {AuthState}
*/
  private authState: AuthState


  /**
   * The pagination used to manage the list of Terminal Operators in the UI
   * and swapping between pages of results.
   * @private
   * @property
   * @see {@link Pagination}
   * @returns {Pagination}
   */
  private pagination = new Pagination<User>()

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link UserRepository}
   * @returns {UserRepository}
   */
  private repo = new UserRepository()

  /**
    * A back-end store for the navigation stack.
   * This should only be used when the UI allows a user to tap a link
   * to a new page, but should be able to return to the current page and return
   * to the existing state.
  * @private
   * @property
   * @see {@link Route}
   * @returns {Route}
   */
  private returnRoutes = new Array<Route>()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.authState = new AuthState(store)
    this.queryParams.pageSize = 10
    this.queryParams.sortBy = 'name'
    this.queryParams.sortDesc = false
    this.status = (this.hasState) ? ViewStatus.SUCCEEDED : ViewStatus.NONE
    this.user = new UserEditor(new User(), store)

    const lookupState = new LookupState(store)
    this.userRoles = lookupState.userRoles.filter(r => r.level > this.authState.user.roles[0].level)

    if (!this.hasState) {
      this.fetch()
    }
  }


  /**
   * The total number of Terminal Operators which match the search criteria
   * @property
   * @returns {number}
   */
  public get count() {
    return this.pagination.totalCount
  }

  /**
   * Whether there are more Terminal Operators that can be retrieved or
   * they have all been retrieved already
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return !this.pagination.hasAllPages
  }

  /**
   * Whether the current State has retrieved any Terminal Stations to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.count > 0
  }

  /**
   * Whether the current Terminal Operator has been edited
   * @property
   * @returns {boolean}
   */
  public get isDirty() {
    return this.user.isDirty
  }

  /**
   * A key/value map to display Terminal Operators in a table
   * @property
   */
  public get tableFields() {
    return [
      {
        key: 'givenNames',
        label: 'Name',
        sortable: true
      },
      {
        key: 'surname',
        label: 'Surname',
        sortable: true
      },
      {
        key: 'userName',
        label: 'Username',
        sortable: true
      },
      {
        key: 'roles',
        label: 'Role',
        sortable: false
      },
      {
        class: 'text-center wpx-50',
        key: 'hasFingerPin',
        label: 'Finger',
        sortable: true
      },
      {
        class: 'text-center wpx-50',
        key: 'isActive',
        label: 'Active',
        sortable: true
      },
      {
        class: 'text-right',
        key: 'more',
        label: '',
        sortable: false
      }
    ]
  }

  /**
   * A list of Terminal Operators for the current page
   * @property
   * @returns {User[]} The list of Terminal Operators for the current page
   */
  public get users() {
    return this.pagination.page()
  }

  /**
   * The total number of pages of results
   * @property
   * @returns {number}
   */
  public get totalPages() {
    return this.pagination.totalPages
  }


  /**
  * Clears the Terminal Operators currently retrieved and resets pagination
  * @function
  * @returns {void}
  */
  public clear() {
    this.status = ViewStatus.NONE
    this.pagination.reset()
  }

  /**
   * Retrieves a list of Terminal Operators from the API.
   * If the current page is the first page, pagination will be reset to start again
   * otherwise the status is updating
   * @function
   * @returns {User[]} The list of Organisations
   */
  public async fetch() {
    this.status = (this.queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    if (this.status == ViewStatus.IN_PROGRESS) {
      this.pagination.reset()
    }

    return await this.getUsers()
  }

  /**
    * Retrieves a list of Terminal Operators for the specified page.
   * If the current pages results have already been retrieved from the API it will
   * return them from memory, otherwise the results will be retrieved from the API
   * @function
   * @param {number} page - The page number
   * @returns {User[]} The list of Organisations
   */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.users
      }

      this.status = ViewStatus.UPDATING

      return await this.getUsers()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Removes the last route from the navigation stack and returns it.
   * This should be called when the user returns to the page by clicking
   * on a back link from the UI.* @function
   * @function
   * @returns {Route} The last route from the navigation stack
   */
  public popRoute() {
    const route = _.last(this.returnRoutes)
    this.returnRoutes.pop()

    return route
  }

  /**
   * Adds a new route to the navigation stack.
   * This should be called when the user navigates away from a page but will
   * have the option to return back on the destination page.
   * @function
   * @param {Route} route - The route to add to the stack
   * @return {void}
   */
  public pushRoute(route: Route) {
    this.returnRoutes.push(route)
  }

  /**
   * Resets the editor and undoes all changes made
   * @function
   * @returns {void}
   */
  public reset() {
    this.user.reset()
  }

  /**
   * Saves the edited Terminal Operator and updates
   * the existing records
   * @function
   * @returns {void}
   */
  public async save() {
    try {
      this.status = ViewStatus.SAVING
      await this.user.save()
      await this.fetch()

      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Sets the current Terminal Operator for the state
   * @function
   * @returns {void}
   */
  public setUser(user: User) {
    this.user.set(user)
  }

  /**
   * Retrieves a new list of Terminal Operator in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {User[]} The list of sorted Terminal Operators
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getUsers()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }


  /**
   * Retrieves a list of Terminal Operators from the API using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.* @private
   * @private 
   * @function
   * @param {QueryParams} queryParams - A pre-defined set of parameters to filter and sort results
   * @returns {User[]} The list of Terminal Operators
   */
private async getUsers() {
    try {
      const response = await this.repo.getUsers(this.queryParams)

      if (this.status == ViewStatus.SORTING) {
        this.pagination.reset(true)
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalPages = response.totalPages
      this.pagination.totalCount = response.totalCount
      this.status = ViewStatus.SUCCEEDED

      return this.users
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }
}
