import { render, staticRenderFns } from "./TerminalLocationSearchResults.vue?vue&type=template&id=5c6b4885&scoped=true&"
import script from "./TerminalLocationSearchResults.vue?vue&type=script&lang=ts&"
export * from "./TerminalLocationSearchResults.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c6b4885",
  null
  
)

export default component.exports