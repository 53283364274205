
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { TerminalLocation } from '@/ts/models/terminalLocation'
  import { TerminalLocationsState } from '@/ts/states/admin/terminalLocationsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class TerminalLocationSearchResults extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: TerminalLocationsState


    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get canEditLocations() {
      return this.authState.user.hasPermission(Claims.ADMIN_LOCATIONS, Crud.UPDATE)
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.state.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.status > ViewStatus.NONE
    }

    protected get queryParams() {
      return this.state.queryParams
    }

    protected get status() {
      return this.state.status
    }

    protected get terminalLocations() {
      return this.state.terminalLocations
    }



    /** EVENTS */

    protected onPageChanged(page: number) {
      this.state.paginate(page)
    }

    protected onRowClicked(terminalStation: TerminalLocation) {
      this.$emit('click', terminalStation)
    }

    protected onSortChanged(context: ISortContext) {
      this.state.sort(context)
    }
  }

