
  import { Component, Vue } from 'vue-property-decorator'
  import { TerminalLocation } from '@/ts/models/terminalLocation'
  import { TerminalLocationsState } from '@/ts/states/admin/terminalLocationsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import TerminalLocationForm from '@/components/forms/TerminalLocationForm.vue'
  import TerminalLocationSearchForm from './components/TerminalLocationSearchForm.vue'
  import TerminalLocationSearchResults from './components/TerminalLocationSearchResults.vue'


  @Component({
    components: {
      TerminalLocationForm,
      TerminalLocationSearchForm,
      TerminalLocationSearchResults
    }
  })
  export default class TerminalLocations extends Vue {


    /** PUBLIC PROPERTIES **/

    protected state = new TerminalLocationsState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get status() {
      return this.state.status
    }


    /** EVENTS */

    protected onClick(terminalLocation = new TerminalLocation()) {
      this.state.reset()
      this.state.setTerminalLocation(terminalLocation)
      this.$bvModal.show('add-edit-location')
    }
  }

