import { Store } from 'vuex'

import { ISortContext } from '@/ts/interfaces/sortContext'
import { Organisation } from '@/ts/models/organisation'
import { Pagination } from '@/ts/models/pagination'
import { QueryParams } from '@/ts/api/queryParams'
import { TerminalStation } from '@/ts/models/terminalStation'
import { TerminalStationEditor } from '@/ts/editors/terminalStationEditor'
import { TerminalStationRepository } from '@/ts/repositories/terminalStationRepository'
import { Route } from '@/ts/models/route'
import { ViewStatus } from '@/ts/enums/viewStatus'


import _ from 'lodash'

/**
 * State management of Terminal Station searches and UI
 * @class
 */
export class TerminalStationsState {

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE

  /** 
   * A Terminal Station Editor that is used to store the original
   * values of the editor along with the changed/new values for
   * editing purposes/
   * @property
   * @see {@link TerminalStationEditor}
   * @returns {TerminalStationEditor}
  */
  public terminalStation: TerminalStationEditor


  /**
   * A back-end store for a selected Organisation.
   * This will only be populated when the current user can access multiple
   * organisations, such as a NightKey administrator.
   * @private
   * @property
   * @see {@link Organisation}
   * @returns {Organisation}
   */
  private org?: Organisation


  /**
   * The pagination used to manage the list of Terminal Stations in the UI
   * and swapping between pages of results.
   * @private
   * @property
   * @see {@link Pagination}
   * @returns {Pagination}
   */
  private pagination = new Pagination<TerminalStation>()

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link TerminalStationRepository}
   * @returns {TerminalStationRepository}
   */
  private repo = new TerminalStationRepository()

  /**
   * A back-end store for the navigation stack.
   * This should only be used when the UI allows a user to tap a link
   * to a new page, but should be able to return to the current page and return
   * to the existing state.
   * @private
   * @property
   * @see {@link Route}
   * @returns {Route}
   */
  private returnRoutes = new Array<Route>()
  

  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.queryParams.pageSize = 10
    this.queryParams.sortBy = 'name'
    this.queryParams.sortDesc = false
    this.terminalStation = new TerminalStationEditor(new TerminalStation(), store)

    if (!this.hasState) {
      this.fetch()
    }
  }


  /**
   * The total number of Terminal Stations which match the search criteria
   * @property
   * @returns {number}
   */
  public get count() {
    return this.pagination.totalCount
  }

  /**
   * Whether there are more Terminal Stations that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return !this.pagination.hasAllPages
  }

  /**
   * Whether the current State has retrieved any Terminal Stations to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.count > 0
  }

  /**
   * Whether the current Terminal Location has been edited
   * @property
   * @returns {boolean}
  */
  public get isDirty() {
    return this.terminalStation.isDirty
  }

  /**
   * The selected Organisation the current Terminal Stations belong to
   * @property
   * @returns {Organisation}
   * @see {@link Organisation}
   */
  public get organisation() {
    return this.org
  }

  /**
   * Sets the State Organisation, or clears it.  Setting an Organisation
   * will clear the results and update the Query Params.
   * @property
   * @returns {void}
   */
  public set organisation(value: Organisation | undefined) {
    this.org = value
    this.queryParams.venues = (value != null) ? [value] : undefined
    this.clear()
  }

  /**
 * A key/value map to display Terminal Locations in a table
 * @function
 * @returns key/value pairs
 */
  public get tableFields() {
    const fields = [
      {
        key: 'name',
        label: 'Terminal name',
        sortable: true
      },
      {
        key: 'organisation.name',
        label: 'Organisation',
        sortable: true
      },
      {
        key: 'terminalLocation.name',
        label: 'Location',
        sortable: true
      },
      {
        class: 'text-center wpx-50',
        key: 'isActive',
        label: 'Active',
        sortable: true
      },
      {
        class: 'text-right',
        key: 'more',
        label: '',
        sortable: false
      }
    ]

    if (this.organisation != null) {
      fields.splice(1, 1)
    }

    return fields
  }


  /**
  * The list of the Terminal Stations for the current page
  * @property
  * @returns {TerminalStation[]
  * @see {@link TerminalStation}
  */
  public get terminalStations() {
    return this.pagination.page()
  }

  /**
   * The total number of pages of results
   * @property
   * @returns {number}
   */
  public get totalPages() {
    return this.pagination.totalPages
  }


  /**
  * Clears the Terminal Stations currently retrieved and resets pagination
  * @function
  * @returns {void}
  */
 public clear() {
    this.status = ViewStatus.NONE
    this.pagination.reset()
  }

  /**
   * Retrieves a list of Terminal Stations from the API.
   * If the current page is the first page, pagination will be reset to start again
   * otherwise the status is updating
   * @function
   * @returns {TerminalStation[]} The list of Terminal Stations
   */
  public async fetch() {
    this.status = (this.queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    if (this.status == ViewStatus.IN_PROGRESS) {
      this.pagination.reset()
    }

    return await this.getTerminalStations()
  }

  /**
   * Retrieves a list of Terminal Stations for the specified page.
   * If the current pages results have already been retrieved from the API it will
   * return them from memory, otherwise the results will be retrieved from the API
   * @function
   * @param {number} page - The page number
   * @returns {TerminalLocation[]} The list of Terminal Stations
   */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.terminalStations
      }

      this.status = ViewStatus.UPDATING

      return await this.getTerminalStations()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Removes the last route from the navigation stack and returns it.
   * This should be called when the user returns to the page by clicking
   * on a back link from the UI.
   * @function
   * @returns {Route} The last route from the navigation stack
   * @see {@link Route}
   */
  public popRoute() {
    const route = _.last(this.returnRoutes)
    this.returnRoutes.pop()

    return route
  }

  /**
   * Adds a new route to the navigation stack.
   * This should be called when the user navigates away from a page but will
   * have the option to return back on the destination page.
   * @function
   * @param {Route} route - The route to add to the stack
   * @return {void}
   */
  public pushRoute(route: Route) {
    this.returnRoutes.push(route)
  }

  /**
 * Resets the editor and undoes all changes made
 * @function
 * @returns {void}
 */
  public reset() {
    this.terminalStation.reset()
    this.status = ViewStatus.NONE
  }

  /**
   * Saves the edited Terminal Station and updates
   * the existing records
   * @function
   * @returns {void}
   */
  public async save() {
    try {
      this.status = ViewStatus.SAVING
      await this.terminalStation.save()
      await this.fetch()

      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Sets the current Terminal Station for the state
   * @function
   * @returns {void}
   */
  public setTerminalStation(terminalStation: TerminalStation) {
    this.terminalStation.set(terminalStation)
  }

  /**
   * Retrieves a fresh list of Terminal Stations in the new specified sort order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {TerminalStation[]} The list of sorted Terminal Stations
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getTerminalStations()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }


  /**
   * Retrieves a list of Terminal Stations from the API using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.
   * @property
   * @returns {TerminalStation[]} The list of Terminal Stations for the current page
   */
  private async getTerminalStations() {
    try {
      const response = await this.repo.getTerminalStations(this.queryParams)

      if (this.status == ViewStatus.SORTING) {
        this.pagination.reset(true)
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalCount = response.totalCount
      this.pagination.totalPages = response.totalPages
      this.status = ViewStatus.SUCCEEDED

      return this.terminalStations
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }
}
