import { ApiResponse } from '../api/apiResponse'
import { HttpStatus } from '../enums/httpStatusCode'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'
import { User, IUser } from '../models/user'

import axios from 'axios'
import qs from 'qs'

export class UserRepository {

  public async getUser(id: string): Promise<User | undefined> {
      const url = this.urlPath(Paths.USERS, id)
      const response = await axios.get(url);
      return new User(response.data as IUser)
  }

  public async getUsers(queryParams: QueryParams): Promise<ApiResponse<User, IUser>> {
      const url = this.urlPath(Paths.USERS)
      const response = await axios.get(url, {
        params: queryParams.toQueryString(),
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
      })

      return new ApiResponse<User, IUser>(response.data, User)
  }

  public async save(user: User): Promise<User | undefined> {
      if (user.id == undefined) {
        const url = this.urlPath(Paths.USERS)
        const response = await axios.post(url, user)
        const iUser = response.data as IUser
        return new User(iUser)
      }
      else {
        const url = this.urlPath(Paths.USERS, user.id)
        const response = await axios.put(url, user)
        if (!HttpStatus.isOK(response.status)) {
          throw response.status
        }

        return user
      }
  }

  public urlPath(route: string, id: string | null = null) {
    let urlPath = route

    if (id != null) {
      urlPath += `/${id}`
    }

    return urlPath
  }
}
