
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { TerminalLocation } from '@/ts/models/terminalLocation'
  import { TerminalLocationsState } from '@/ts/states/admin/terminalLocationsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class TerminalLocationSearchForm extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: TerminalLocationsState


    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERIES */

    protected get canAddLocation() {
      return this.authState.user.hasPermission(Claims.ADMIN_LOCATIONS, Crud.CREATE)
    }

    protected get queryParams() {
      return this.state.queryParams
    }

    protected get searchText() {
      return (this.state.status == ViewStatus.IN_PROGRESS) ? 'Searching...' : 'Update Search'
    }


    /** EVENTS */

    protected onClick() {
      const terminalLocation = new TerminalLocation()
      terminalLocation.isActive = true

      this.$emit('click', terminalLocation)
    }

    protected onSubmit() {
      this.queryParams.currentPage = 1
      this.state.fetch()
    }
  }
