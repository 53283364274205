import { Editor } from './editor'
import { User } from '@/ts/models/user'
import { UserRepository } from '../repositories/userRepository'
import { Store } from 'vuex'
import { UserRole } from '../models/userRole'

/**
 * An editor for Terminal Operators
 * @class
 */
export class UserEditor extends Editor<User> {


  public phoneNumber = ''

  /**
  * The selected role from the dropdown
   * @private
   * @property
   */
  public role = new UserRole()

  /**
   * The repository to make API requests
   * @private
   * @property
   */
  private repo = new UserRepository()


  /**
   * Instantiates a new editor
   * @constructor
   * @param original
   */
  constructor(original = new User(), store: Store<any>) {
    super(original, store)
    this.set(original)
  }


  /**
   * Resets the editor to a new Terminal Operator
   * @function
   */
  public reset() {
    this.edit = new User()
    this.set(new User())
  }

  /**
   * Saves the current edits to a terminal operator and resets the editor when complete
   * @function
   */
  public async save() {
    this.edit.phoneNumber = this.phoneNumber
    this.edit.roles = [this.role]
    await this.repo.save(this.edit)
    this.reset()
  }

  public set(original: User) {
    super.set(original)
    this.role = original.roles[0]
    this.phoneNumber = original.phoneNumber
  }
}
