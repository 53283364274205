
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { TerminalStation } from '@/ts/models/terminalStation'
  import { TerminalStationsState } from '@/ts/states/admin/terminalStationsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'
import { RoleType } from '../../ts/enums/roleType'

 


  @Component
  export default class TerminalStationSearchForm extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: TerminalStationsState


    /**  PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)



    /** COMPUTED PROPERIES */

    protected get canAddTerminal() {
      return this.authState.user.isInRole(RoleType.ADMIN)
    }

    protected get queryParams() {
      return this.state.queryParams
    }

    protected get searchText() {
      return (this.state.status == ViewStatus.IN_PROGRESS) ? 'Searching...' : 'Update Search'
    }


    /** EVENTS */

    protected onClick() {
      this.$emit('click', new TerminalStation())
    }

    protected onSubmit() {
      this.queryParams.currentPage = 1
      this.state.fetch()
    }
  }
