
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Crud } from '@/ts/enums/crud'
  import { TerminalLocation } from '@/ts/models/terminalLocation'
  import { TerminalLocationsState } from '@/ts/states/admin/terminalLocationsState'
  import { ValidationObserver } from 'vee-validate'
  import { IValidationState } from '@/ts/vee-validate/'
  import { ViewStatus } from '@/ts/enums/viewStatus'

@Component
  export default class TerminalLocationForm extends Vue {

    /** PUBLIC PROPERTIES */

    @Prop()
    public id!: string

    @Prop()
    public state!: TerminalLocationsState


    /** PRIVATE PROPERTIES */

    protected showModal = false

    private authState = new AuthState(this.$store)
    private mode = Crud.CREATE


    /** OBSERVERS */

    @Watch('state.terminalLocation.original', { immediate: false, deep: true })
    protected onTerminalLocationChanged(terminalLocation: TerminalLocation) {
      this.mode = (terminalLocation.id == undefined) ? Crud.CREATE : Crud.UPDATE
    }


    /** COMPUTED PROPERTIES */

    protected get editingText() {
      return (this.isUpdating && this.isDirty) ? '[edited]' : ''
    }

    protected get isDirty() {
      return this.state.isDirty
    }

    protected get isFailed() {
      return this.state.status == ViewStatus.FAILED
    }

    protected get isUpdating() {
      return this.mode == Crud.UPDATE
    }

    protected get submitMessage() {
      if (this.state.status == ViewStatus.SAVING) {
        return (this.isUpdating) ? 'Saving...' : 'Adding...'
      }

      return (this.isUpdating) ? 'Save location' : 'Add new location'
    }

    protected get terminalLocation() {
      return this.state.terminalLocation.edit
    }

    protected get title() {
      return (this.mode == Crud.CREATE) ? 'Add new location' : 'Edit location'
    }


    /** EVENTS */

    protected onCancel() {
      if (this.isDirty && this.showModal) {
        this.$bvModal.msgBoxConfirm('You have unsaved changes which will be lost', { title: 'Are you sure?' })
          .then(confirmed => {
            if (confirmed) {
              this.showModal = false
            }
          })
          .catch(err => {
            // An error occurred
          })
      }
      else {
        this.showModal = false
      }
    }

    protected onReset() {
      this.state.reset()

      const form = this.$refs.form as HTMLFormElement
      form?.reset()

      this.$nextTick(() => {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
        observer?.reset()
      })

      this.$emit('cancel')
      this.showModal = false
    }

    protected async onSubmit() {
      const validator = this.$refs.observer as InstanceType<typeof ValidationObserver>
      const isValid = await validator.validate();
      if (isValid) {
        this.state.save()
        this.showModal = false
      }
    }


    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null;
    }

    protected truncate(text: string, limit: number) {
      if (text.length <= limit) {
        return text;
      }

      return text.slice(0, limit) + '...'
    }
  }

