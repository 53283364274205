
  import { Component, Watch, Vue } from 'vue-property-decorator'
  import { QueryParams } from '@/ts/api/queryParams'
  import { User } from '@/ts/models/user'
  import { UsersState } from '@/ts/states/admin/usersState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import UserForm from '@/components/forms/UserForm.vue'
  import UserSearchForm from './components/UserSearchForm.vue'
  import UserSearchResults from './components/UserSearchResults.vue'


  @Component({
    components: {
      UserForm,
      UserSearchForm,
      UserSearchResults
    }
  })
  export default class Users extends Vue {

    /** PRIVATE PROPERTIES */

    protected queryParams!: QueryParams
    protected state = new UsersState(this.$store)



    /** OBSERVERS */

    @Watch('state.queryParams', { immediate: false, deep: true })
    protected onQueryParamsChanged(queryParams: QueryParams) {
      this.queryParams = queryParams
    }

    /** COMPUTED PROPERTIES */

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get status() {
      return this.state.status
    }


    /** EVENTS */

    protected onClick(user = new User()) {
      this.state.reset()
      this.state.setUser(user)
      this.$bvModal.show('add-edit-user')
    }
  }

