
  import { Component, Watch, Vue } from 'vue-property-decorator'
  import { Organisation } from '@/ts/models/organisation'
  import { OrganisationsState } from '@/ts/states/admin/organisationsState'
  import { QueryParams } from '@/ts/api/queryParams'
  import { Route } from '@/ts/models/route'
  import { Routes } from '@/ts/constants/routes'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import VenueSearchForm from './components/VenueSearchForm.vue'
  import VenueSearchResults from './components/VenueSearchResults.vue'


  @Component({
    components: {
      VenueSearchForm,
      VenueSearchResults
    }
  })
  export default class Venues extends Vue {

    /** PRIVATE PROPERTIES */

    protected queryParams!: QueryParams
    protected state = new OrganisationsState(this.$store)


    /** LIFECYCLE  */


    public mounted() {
      if (this.state.hasState) {
        Vue.nextTick(() => {
          window.scrollTo({ left: 0, top: this.state.scrollPosition, behavior: 'auto' })
        })
      }
    }


    /** OBSERVERS */

    @Watch('state.queryParams', { immediate: true, deep: true })
    protected onQueryParamsChanged(queryParams: QueryParams) {
      this.queryParams = queryParams
    }


    /** COMPUTED PROPERTIES */

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get status() {
      return this.state.status
    }


    /** EVENTS */

    protected onClick(organisation: Organisation) {
      this.state.scrollPosition = window.scrollY

      this.state.organisation = organisation
      this.state.pushRoute(new Route(Routes.VENUES, null, 'customers'), true)

      this.$router.push({ name: Routes.VENUE, params: { venueId: organisation.id.toString() } })
    }

    protected onReset() {
      this.state.clear()
    }
  }

