
  import { Component, Vue } from 'vue-property-decorator'

  import AdminNavigation from './components/AdminNavigation.vue'
 

  @Component({
    components: {
      AdminNavigation
    }
  })
  export default class Admin extends Vue { }

