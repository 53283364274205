
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { User } from '@/ts/models/user'
  import { UsersState } from '@/ts/states/admin/usersState'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class VenueSearchForm extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: UsersState


    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERIES */

    protected get canAddOperator() {
      return this.authState.user.hasPermission(Claims.ADMIN_OPERATORS, Crud.CREATE)
    }

    protected get queryParams() {
      return this.state.queryParams
    }

    protected get searchText() {
      return (this.state.status == ViewStatus.IN_PROGRESS) ? 'Searching...' : 'Update Search'
    }


    /** EVENTS */

    protected onClick() {
      const user = new User()
      user.isActive = true

      this.$emit('click', user)
    }

    protected onSubmit() {
      this.queryParams.currentPage = 1
      this.state.fetch()
    }
  }
