
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { OrganisationsState } from '@/ts/states/admin/organisationsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class VenueDetails extends Vue {


    /** PUBLIC PROPERTIES */

    @Prop()
    public state!: OrganisationsState


    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get organisation() {
      return this.state.organisation
    }

    protected get status() {
      return this.state.status
    }

  }

