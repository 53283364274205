
  import { Component, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { OrganisationsState } from '@/ts/states/admin/organisationsState'
  import { Routes } from '@/ts/constants/routes'
  import { TerminalLocationsState } from '@/ts/states/admin/terminalLocationsState'
  import { TerminalStationsState } from '@/ts/states/admin/terminalStationsState'
  import { UsersState } from '@/ts/states/admin/usersState'

  @Component
  export default class AdminNavigation extends Vue {

    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get canSeeCustomers() {
      return this.user.hasPermission(Claims.ADMIN_CUSTOMERS, Crud.READ)
    }

    protected get canSeeTerminalLocations() {
      return this.user.hasPermission(Claims.ADMIN_LOCATIONS, Crud.READ)
    }

    protected get canSeeTerminalStations() {
      return this.user.hasPermission(Claims.ADMIN_STATIONS, Crud.READ)
    }

    protected get canSeeUsers() {
      return this.user.hasPermission(Claims.ADMIN_USERS, Crud.READ)
    }

    protected get user() {
      return this.authState.user
    }

    /** EVENTS */

    protected onTerminalLocations() {
      const state = new TerminalLocationsState(this.$store)
      state.clear()

      this.$router.push({ name: Routes.TERMINAL_LOCATIONS })
    }

    protected onTerminalStations() {
      const state = new TerminalStationsState(this.$store)
      state.clear()

      this.$router.push({ name: Routes.TERMINAL_STATIONS })
    }

    protected onVenues() {
      const state = new OrganisationsState(this.$store)
      state.clear()

      this.$router.push({ name: Routes.VENUES })
    }

    protected onUsers() {
      const state = new UsersState(this.$store)
      state.clear()

      this.$router.push({ name: Routes.USERS })
    }

  }


