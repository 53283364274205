import { render, staticRenderFns } from "./Venues.vue?vue&type=template&id=9c176ef6&scoped=true&"
import script from "./Venues.vue?vue&type=script&lang=ts&"
export * from "./Venues.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c176ef6",
  null
  
)

export default component.exports