
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { QueryParams } from '@/ts/api/queryParams'
  import { TerminalStation } from '@/ts/models/terminalStation'
  import { TerminalStationsState } from '@/ts/states/admin/terminalStationsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import TerminalStationForm from '@/components/forms/TerminalStationForm.vue'
  import TerminalStationSearchForm from '@/components/terminal-stations/TerminalStationSearchForm.vue'
  import TerminalStationSearchResults from '@/components/terminal-stations/TerminalStationSearchResults.vue'



  @Component({
    components: {
      TerminalStationForm,
      TerminalStationSearchForm,
      TerminalStationSearchResults
    }
  })
  export default class TerminalStationSearch extends Vue {

    /** PUBLIC PROPERTIES **/

    @Prop()
    public state!: TerminalStationsState


    /** PRIVATE PROPERTIES */

    protected queryParams = this.state.queryParams


    /* LIFECYCLE */

    public created() {
      if (!this.state.hasState) {
        this.state.fetch()
      }
    }


    /** OBSERVERS */

    @Watch('state.queryParams', { immediate: false, deep: true })
    protected onQueryParamsChanged(queryParams: QueryParams) {
      this.queryParams = queryParams
    }

    /** COMPUTED PROPERTIES */

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get status() {
      return this.state.status
    }


    /** EVENTS */

    protected onClick(terminalStation = new TerminalStation()) {
      this.state.reset()
      this.state.setTerminalStation(terminalStation)
      this.$bvModal.show('add-edit-station')
    }

  }

